.embed-preview {
    margin: 1.5rem 0 2.5rem 0;

    @media @q-sm-min {
        margin: 1.5rem 0 5rem 0;
    }
}

.embed-preview__body {
    .page-container();
    text-align: center;
    margin-top: 1.5rem;
}

.embed-preview__toggle {
    text-align: center;
    .font-size(.875rem, 1.25rem, 1.5rem);
    &:extend(a all);

    span {
        cursor: pointer;
        text-decoration: underline;
    }
}

.embed-preview__code {
    display: inline-block;
    padding: 1rem;
    text-align: left;
    max-width: 100%;
    background: rgba(255, 255, 255, .2);

    @media @q-sm-min {
        padding: 2rem 3rem;
        text-align: center;
    }
}

.embed-preview__info {
    font-size: .875rem;
    margin-top: 1rem;
    opacity: .7;
}