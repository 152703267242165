.mosul-footer {
    margin-top: 3rem;

    @media @q-sm-min {
        margin-top: 6.5rem;
    }
}

.mosul-footer__body {
    .page-container();
}

.mosul-footer__footer {
    .clearfix();
    .page-container();
    margin-bottom: 1rem;

    .font-size(.875rem, 1.25rem, 1.5rem);
    margin-top: 3rem;

    @media @q-sm-min {
        margin-top: 12rem;
        margin-bottom: 2.5rem;
    }
}

.mosul-footer__copyright {
    float: left;
}

.mosul-footer__credits {
    float: right;

    a {
        color: @color-lightest;
    }
}