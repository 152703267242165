/*
|--------------------------------------------------------------------------
| Buttons
|--------------------------------------------------------------------------
|
| Buttons used in the website.
|
*/

button {

}

.btn {
    display: inline-block;
    height: auto;
    padding: .5em .875em;

    border-radius: 3px;
    box-shadow: none;
    background: @color-cms-1;
    color: @color-darkest;
    font-size: 1rem;

    border: none;
    text-align: center;
    cursor: pointer;

    transition: @transition-button;

    &:hover {
        background: darken(@color-cms-1, 10%);
    }
}

.btn--primary {
    background: @color-primary;
    color: @color-lightest;

    &:focus {
        background: darken(@color-primary, 10%);
    }

    &:hover {
        background: darken(@color-primary, 20%);
    }

    &:active {
        background: lighten(@color-primary, 10%);
    }

    &:focus,
    &:hover,
    &:active {
        color: @color-lightest;
    }
}

.btn--secondary {
    background: @color-secondary;
    color: @color-lightest;

    &:focus {
        background: darken(@color-secondary, 10%);
    }

    &:hover {
        background: darken(@color-secondary, 20%);
    }

    &:active {
        background: lighten(@color-secondary, 10%);
    }

    &:focus,
    &:hover,
    &:active {
        color: @color-lightest;
    }
}

.btn--success {
    background: @color-highlight-success;
    color: @color-lightest;

    &:hover {
        background: darken(@color-highlight-success, 10%);
    }
}

.btn--error {
    background: @color-highlight-error;
    color: @color-lightest;

    &:hover {
        background: darken(@color-highlight-error, 10%);
    }
}

.btn--link {
    padding: 0;
    text-decoration: underline;
}

.btn--small {
    font-size: .875rem;
}

.btn--wide {
    width: 100%;
}

.btn--clean {
    border: none;
    background: transparent;
    outline: none;
    box-shadow: none;

    &:hover {
        background: transparent;
    }
}


