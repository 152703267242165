/* Page */
.page-enter-active, .page-leave-active {
    transition: @transition-page;
    transform-origin: center center;
}

.page-enter, .page-leave-to {
    opacity: 0;
    transform: translateY(.1rem) scale(.995);
}

/* Fade */
.fade-enter-active, .fade-leave-active {
    transition: @transition-fade;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}