//
// Utility classes
// --------------------------------------------------
// Floats
// -------------------------
.clearfix {
    .clearfix();
}

.center-block {
    .center-block();
}

.pull-right {
    float: right !important; /* stylelint-disable-line */
}

.pull-left {
    float: left !important; /* stylelint-disable-line */
}

// Toggling content
// -------------------------
// Note: Deprecated .hide in favor of .hidden or .sr-only (as appropriate) in v3.0.1
.hide {
    display: none !important; /* stylelint-disable-line */
}

.show {
    display: block !important; /* stylelint-disable-line */
}

.invisible {
    visibility: hidden;
}

// Hide from screenreaders and browsers
//
// Credit: HTML5 Boilerplate
.hidden {
    display: none !important; /* stylelint-disable-line */
}