.page-container() {
    max-width: @page-max-width;
    padding-left: @page-side-spacing-mobile;
    padding-right: @page-side-spacing-mobile;
    margin: 0 auto;

    @media @q-sm-min {
        padding-left: @page-side-spacing-desktop;
        padding-right: @page-side-spacing-desktop;
    }
}

// Center-align a block level element
.center-block() {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.cover-block() {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.size(@width; @height) {
    width: @width;
    height: @height;
}

.square(@size) {
    .size(@size; @size);
}
