/*
|--------------------------------------------------------------------------
| Figures
|--------------------------------------------------------------------------
|
| Default way figures are styled
|
*/

figure {

}

figcaption {
    margin-top: @leading / 2;
    margin-bottom: @leading * 2;
    color: @color-gray-1;
}