.video-compare {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
}

.video-compare__background,
.video-compare__foreground {
    .cover();
    overflow: hidden;
}

.video-compare__background {
}

.video-compare__foreground {
    right: 50%;
    // opacity: .92;
}

.video-compare__content {
    max-height: 100%;

    video {
        width: 100%;
        margin: 0;
    }
}

.video-compare__handle {
    width: 1rem;
    position: absolute;
    top: 0;
    bottom: 0;
    transform: translateX(-.5rem);
    cursor: move;
    cursor: grab;

    &:active {
        cursor: grabbing;
    }
}

.video-compare__handle-knob {
    position: absolute;
    top: 50%;
    left: 50%;
    .square(4.25rem);

    background: url('/img/app/icons/knob.svg') no-repeat center center;
    background-size: 100% 100%;
    transform: translate(-50%, -50%);
}

.video-compare__loader {
    .cover();
    background: black;
    color: white;

    .spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}