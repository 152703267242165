/*
|--------------------------------------------------------------------------
| Radio buttons
|--------------------------------------------------------------------------
*/

.radio {
    display: block;
    padding: 0;

    &.radio--inline {
        display: inline-block;
    }

    input[type='radio'] {
        display: none;
    }

    .radio__label {
        display: inline-block;
        position: relative;
        top: 1px;
        vertical-align: top;
    }

    .radio__custom {
        display: inline-block;
        position: relative;
        width: 1.5rem;
        height: 1.5rem;
        margin-right: .5rem;

        border: 1px solid @color-gray-1;
        border-radius: 50%;
        background-color: @color-lightest;
        vertical-align: middle;

        transition: @transition-input;
    }


    // Checkbox
    input[type='radio'] + .radio__custom:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;

        display: block;
        width: 14px;
        height: 10px;

        background: @color-darkest;
        border-radius: 50%;

        opacity: 0;
        transform: translate(-50%, -50%);
        transition: @transition-input;
    }

    // Checked
    input[type='radio']:checked + .radio__custom:after {
        opacity: 1;
    }
}
