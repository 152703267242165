/*
|--------------------------------------------------------------------------
| Layout
|--------------------------------------------------------------------------
|
| Global layout related styling should be added to this file.
|
*/

html {

}

body {
    background: @color-body-bg;
}