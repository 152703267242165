/* Form errors */
ul.form-errors {
    &:extend(.list--clean all);

    width: 100%;
    margin: 0;
    overflow: hidden;

    background: @color-lightest;

    @media @q-xs-min {
        float: left;
    }
}

li.form-errors__message {
    margin: .5rem .25rem;
    font-size: .875rem;
    color: @color-highlight-error;
}

input[readonly='readonly'] + ul.form-errors {
    display: none;
}