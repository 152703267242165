.video-controls {
    .clearfix();

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;

    padding: 0 1rem .875rem 1rem;

    .btn {
        height: 1.5rem;
        padding: 0;
        line-height: 1.5rem;
        overflow: hidden;
    }
}

.video-controls__timeline {
    position: relative;
    height: .25rem;
    width: 100%;
    background: fade(@color-lightest, 50%);

    cursor: pointer;
}

.video-controls__track {
    .cover();
    z-index: 3;
}

.video-controls__progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: @color-lightest;
    transition: width 200ms 0ms linear;
}

.video-controls__play,
.video-controls__audio {
    margin-top: .75rem;
}

.video-controls__play {
    float: left;

    .btn {
        padding: 0 .75rem;

        img {
            width: auto;
            height: 1.25rem;
        }
    }
}

.video-controls__audio {
    float: right;
}