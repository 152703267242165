/*
|--------------------------------------------------------------------------
| Article
|--------------------------------------------------------------------------
|
| This class should be applied to default body text, mostly used on
| blog pages or default page templates.
|
| Use this to scope certain element styling for article purpose instead of
| applying 'article' styles to the root of the document.
|
| NOTE: Typography helper classes like 'lead' or text helpers should be
| defined in 'typography.less'. If needed they can be overwritten here.
|
*/

.article {
    h1, h2, h3, h4, h5, h6, p {
        max-width: @content-max-width;
        margin-left: auto;
        margin-right: auto;
    }

    h1, h2, h3 {
        margin-top: @leading * 2;
    }

    h4, h5, h6 {
        margin-top: @leading;

        & + p {
            margin-top: @leading/4;
        }
    }

    h2 {
        margin: @leading * 3 0 @leading 0;
    }

    h3 {
        margin: @leading * 2 0 @leading 0;
    }

    .lead {
        text-align: center;
        margin-top: 4em;
        margin-bottom: 4em;
    }

    p {
        .font-size(.875rem, 1.25rem, 1.5rem);
        line-height: 1.5;
        margin-top: 2em;
        margin-bottom: 2em;

        img {
            margin: 0;
        }
    }

    a {
        color: @color-primary;
        text-decoration: none;

        &:focus {

        }

        &:hover {
            text-decoration: underline;
        }

        &:active {

        }
    }

    blockquote {

    }

    q {

    }

    img,
    video {
        max-width: 100%;
        height: auto;
    }

    iframe {
        max-width: 100%;
    }

    figure,
    video,
    iframe {
        margin: @leading 0;
    }

    table {
        width: 100%;
        margin: @leading 0;
    }

    @media @q-sm-min {
        p {
            line-height: 1.75;
        }
    }
}