/*
|--------------------------------------------------------------------------
| Tables
|--------------------------------------------------------------------------
|
| Default way tables are styled.
|
*/

table {
    border-collapse: collapse;
    border-radius: 2px;

    thead {
        background: @color-secondary;
        color: @color-lightest;

        tr {
            border-color: @color-secondary;
        }
    }

    tbody {

    }

    tr {
        border-bottom: 1px solid fade(@color-darkest, 10%);
        border-right: 1px solid fade(@color-darkest, 10%);
        border-left: 1px solid fade(@color-darkest, 10%);
    }

    th, td {
        padding: .5rem .75rem;
        border-right: 1px solid fade(@color-darkest, 10%);

        &:last-child {
            border: none;
        }
    }

    th {
        text-align: left;
    }

    td {

    }
}

.table--wide {
    width: 100%;
}