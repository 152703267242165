/*
|--------------------------------------------------------------------------
| Typography
|--------------------------------------------------------------------------
|
| Global typography rules & helpers.
| Make sure all styling in this file can be applied to the whole
| of the document. Don't be too specific so styles don't
| have to be overwritten constantly within in components.
|
*/

/* ==========================================================================
    Quick references to often used font styles
   ========================================================================== */

.font-primary {
    font-family: @font-family-primary;
    font-weight: @font-weight-primary-regular;
}

.font-primary-regular {
    font-family: @font-family-primary;
    font-weight: @font-weight-primary-regular;
}



/* ==========================================================================
    Quick references to font sizes
   ========================================================================== */
.font-size-h1 { .font-size(1.75rem, 2rem, 2.5rem); }
.font-size-h2 { .font-size(2rem, 2rem, 2rem); }
.font-size-h3 { .font-size(1.5rem, 1.5rem, 1.5rem); }
.font-size-h4 { .font-size(1.25rem, 1.25rem, 1.25rem); }
.font-size-h5 { .font-size(1rem, 1rem, 1rem); }
.font-size-h6 { .font-size(1rem, 1rem, 1rem); }

.font-size-lead { .font-size(1.125rem, 1.25rem); }
.font-size-text { .font-size(1rem, 1.125rem); }


/* ==========================================================================
    Quick references to often used font styles
   ========================================================================== */
.font-h1 { &:extend(.font-size-h1); line-height: 1; }
.font-h2 { &:extend(.font-size-h2); line-height: 1; }
.font-h3 { &:extend(.font-size-h3); line-height: 1.333; }
.font-h4 { &:extend(.font-size-h4); line-height: 1.333; }

.font-base { &:extend(.font-size-base); line-height: 1.333; }
.font-lead { &:extend(.font-size-lead); line-height: 1.5; }
.font-text { &:extend(.font-size-text); line-height: 1.666; }



/* ==========================================================================
   Base type
   ========================================================================== */

html {
    font-size: @font-size-root;
}

body {
    .font-primary();
    .font-base();
    .antialiased();

    color: @color-body-text;
}


/* ==========================================================================
    Headings
   ========================================================================== */

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;

    // Make sure any CMS added paragraphs inherit main styling.
    p {
        margin: 0;
        padding: 0;
        font-size: inherit;
        line-height: inherit;
    }

    // Make sure titles cannot be made bolder in the cms.
    strong, b {
        font-weight: inherit;
    }

    // Make sure headings don't have a margin at their top when they're the first element.
    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

h1 {
    &:extend(.font-h1);
    margin-top: @leading * 3;
    margin-bottom: @leading * 2;
}

h2 {
    &:extend(.font-h2);
    margin-top: @leading * 2.5;
    margin-bottom: @leading * 1.5;
}

h3 {
    &:extend(.font-h3);
    margin-top: @leading * 2;
    margin-bottom: @leading * 1;
}

h4 {
    &:extend(.font-h4);
    margin-top: @leading * 1;
    margin-bottom: @leading * .5;
}

h5 { &:extend(.font-h5); }
h6 { &:extend(.font-h6); }


/* ==========================================================================
    Links
   ========================================================================== */

a {
    transition: @transition-link;

    &:focus, &:active, &:visited, &:hover {
        text-decoration: none;
        outline: none;
    }
}

.cleanlink,
.link-clean,
.link--clean {
    text-decoration: none;
    color: inherit;
    background: none;
    border-color: transparent;

    &:hover, &:focus, &:active, &:visited {
        text-decoration: none;
        background: none;
        border-color: transparent;
        color: initial;
    }
}



/* ==========================================================================
    Paragraphs
   ========================================================================== */

p {
    &:extend(.font-base);
    margin: 0 auto 1.5em 0;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.lead {
    &, p {
        &:extend(.font-lead);
    }
}

pre {
    background: @color-gray-1;
    padding: @leading;
    font-family: Courier, monospace;
}


/* ==========================================================================
    Text helpers
   ========================================================================== */

// Transformation
.text--emphasized,
.text--primary      { color: @color-lightest; }
.text--capitalize   { text-transform: capitalize; }
.text--lowercase    { text-transform: lowercase; }
.text--uppercase    { text-transform: uppercase; }

// Alignment
.text--left, .text-left             { text-align: left; }
.text--right, .text-right           { text-align: right; }
.text--center, .text-center         { text-align: center; }
.text--justify, .text-justify       { text-align: justify; }
.text--nowrap                       { white-space: nowrap; }
.text--middle                       { vertical-align: middle; }

.text--super {
    position: relative;
    vertical-align: super;
    font-size: .4em;
    top: -.2em;
}
