/*
|--------------------------------------------------------------------------
| Checkboxes
|--------------------------------------------------------------------------
*/

.checkbox {
    display: block;
    padding: 0;

    &.checkbox--inline {
        display: inline-block;
    }

    input[type='checkbox'] {
        display: none;
    }

    .checkbox__label {
        display: inline-block;
        position: relative;
        top: 1px;
        vertical-align: top;
    }

    .checkbox__custom {
        display: inline-block;
        position: relative;
        width: 1.5rem;
        height: 1.5rem;
        margin-right: .5rem;

        border: 1px solid @color-gray-1;
        background-color: @color-lightest;
        vertical-align: middle;

        transition: @transition-input;
    }

    // Checkbox
    input[type='checkbox'] + .checkbox__custom:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;

        display: block;
        width: 14px;
        height: 10px;

        background: @color-darkest;

        opacity: 0;
        transform: translate(-50%, -50%);
        transition: @transition-input;
    }

    // Checked
    input[type='checkbox']:checked + .checkbox__custom:after {
        opacity: 1;
    }
}
