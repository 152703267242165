// Placeholder text
.placeholder(@color: @input-color-placeholder) {
    // Firefox
    &::-moz-placeholder {
        color: @color;
        opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
    }

    &:-ms-input-placeholder {
        color: @color;
    } // Internet Explorer 10+

    &::-webkit-input-placeholder {
        color: @color;
    } // Safari and Chrome
}