.mosul-map {
    position: relative;
    .set-ratio(56.4%);
    margin-bottom: 2rem;

    @media @q-sm-min {
        margin-top: 5rem;
        margin-bottom: 5rem;
    }
}

.mosul-map__map {
    .cover();

    .marker {
        color: @color-darkest;
        font-size: 1rem;
    }
}