.mosul-hero {
    position: relative;
    margin: 1rem 0 2.5rem 0;

    img {
        width: 100%;
        max-width: none;
        height: auto;
    }

    @media @q-sm-min {
        margin: 2rem 0 3.25rem 0;
    }
}

.mosul-hero__image-mobile {
    @media @q-sm-min {
        display: none;
    }
}

.mosul-hero__image-desktop {
    @media @q-sm-max {
        display: none;
    }
}

.mosul-hero__top {
    .clearfix();
    .page-container();
    margin-bottom: 1.5rem;

    .font-size(.875rem, 1.25rem, 1.5rem);
}

.mosul-hero__credits {
    float: left;
}

.mosul-hero__switch {
    float: right;
    text-align: right;

    span {
        color: fade(@color-lightest, 48%);
    }

    a {
        color: @color-lightest;
    }
}