/*
|--------------------------------------------------------------------------
| Form layout
|--------------------------------------------------------------------------
*/

form {

}

fieldset {
    width: 100%;
    margin: 2.5rem 0;
    padding: 00;
    border: none;

    &:first-child,
    &:first-of-type {
        margin-top: 0;
    }

    &:last-child {
        border-bottom: none;
    }
}

/* Form section */
.form__section {
    &:extend(.clearfix all);
    position: relative;
    padding-bottom: 1.5rem;

    .form__section__remove {
        position: absolute;
        top: 2rem;
        right: 0;
        z-index: 2;
    }

    &[data-form-section-removable='false'] {
        .form__section__remove {
            display: none;
        }
    }

    + .form__section {
        border-top: 1px dashed @color-gray-1;
        padding-top: 1.5rem;
    }

    @media @q-sm-max {
        .form__section__remove {
            .btn {
                width: 1.5rem;
                height: 1.5rem;
                line-height: 1.5rem;
            }
        }
    }

    @media @q-sm-min {
        padding-bottom: 2rem;

        + .form__section {
            padding-top: 2rem;
        }

        .form__section__remove {
            top: 2rem;
            right: 1.5rem;
        }
    }
}



/* Form group wrapper */
.form__group-wrapper {
    &:extend(.clearfix all);

    & + .form__group,
    & + .form__group-wrapper,
    & + .form__section {
        margin-top: 1rem;

        &.form__group--add-space-top {
            margin-top: 2rem;
        }

        &.form__group--add-space-bottom {
            margin-bottom: 2rem;
        }
    }

    @media @q-xs-min {
        .form__group {
            float: left;
            width: 100%;
            padding-right: .25rem;
            padding-left: .25rem;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }

            & + .form__group {
                margin-top: 0;
            }
        }

        .form__group--one-fifth {
            width: 20%;

            input {
                font-size: .875rem;
            }
        }

        .form__group--two-fifth {
            width: 40%;
        }

        .form__group--three-fifth {
            width: 60%;
        }

        .form__group--four-fifth {
            width: 80%;
        }
    }
}


/* Form group */
.form__group {
    width: 100%;
    max-width: 40rem;

    & + .form__group,
    & + .form__group-wrapper,
    & + .form__section {
        margin-top: 1rem;

        &.form__group--add-space-top {
            margin-top: 2rem;
        }

        &.form__group--add-space-bottom {
            margin-bottom: 2rem;
        }
    }

    p {
        &:extend(.font-size-base);
    }

    .form__notice {
        font-size: .875rem;
    }

    @media @q-xs-max {
        .btn {
            width: 100%;
        }
    }
}

.form__group--framed {
    padding: 1rem 1rem 1rem 1.5rem;
    background: @color-lightest;
    border: 1px dashed @color-gray-1;

    @media @q-xs-max {
        max-width: 100%;
    }
}

.form__field-wrapper {
    .clearfix();
}

/* ==========================================================================
    Form modifiers
   ========================================================================== */

