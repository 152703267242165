/*
|--------------------------------------------------------------------------
| Item grid
|--------------------------------------------------------------------------
|
| This grid is mainly used to display smaller, repeated, content pieces within the main grid.
| It's possible to define the column count for different breakpoints by adding data attributes
| to the container.
|
| Example: data-columns-xxs='1' data-columns-xs='1' data-columns-sm='2' data-columns-md='3' data-columns-lg='4'  data-columns-xl='5'
|
| <div class='item-grid {$modifiers}' data-columns-xs='1' data-columns-sm='2' data-columns-md='3' data-columns-lg='4'  data-columns-xl='5'>
|     <div class='item-grid__item'>
|         <component></component>
|     </div>
|     <div class='item-grid__item'>
|         <component></component>
|     </div>
|     <div class='item-grid__item'>
|         <component></component>
|     </div>
|     <div class='item-grid__item'>
|         <component></component>
|     </div>
|     <div class='item-grid__item'>
|         <component></component>
|     </div>
| </div>
|
|
*/

@item-gutter--xxs: 1rem;
@item-gutter--xs: 1rem;
@item-gutter--sm: 1rem;
@item-gutter--md: 1.5rem;
@item-gutter--lg: 1.5rem;

@item-gutter--xxs-half: @item-gutter--xxs / 2;
@item-gutter--xs-half: @item-gutter--xs / 2;
@item-gutter--sm-half: @item-gutter--sm / 2;
@item-gutter--md-half: @item-gutter--md / 2;
@item-gutter--lg-half: @item-gutter--lg / 2;

.item-grid {
    &:extend(.clearfix all);

    width: ~'calc(100% + @{item-gutter--xxs})';
    margin-left: -@item-gutter--xxs-half;

    @media @q-xs-min {
        width: ~'calc(100% + @{item-gutter--xs})';
        margin-left: -@item-gutter--xs-half;
    }

    @media @q-sm-min {
        width: ~'calc(100% + @{item-gutter--sm})';
        margin-left: -@item-gutter--sm-half;
    }

    @media @q-md-min {
        width: ~'calc(100% + @{item-gutter--md})';
        margin-left: -@item-gutter--md-half;
    }

    @media @q-lg {
        width: ~'calc(100% + @{item-gutter--lg})';
        margin-left: -@item-gutter--lg-half;
    }
}

.item-grid__item {
    float: left;
    padding: 0 @item-gutter--xxs-half;
    width: 100%;

    @media @q-xs-min {
        padding: 0 @item-gutter--xs-half;
    }

    @media @q-sm-min {
        padding: 0 @item-gutter--sm-half;
    }

    @media @q-md-min {
        padding: 0 @item-gutter--md-half;
    }

    @media @q-lg {
        padding: 0 @item-gutter--lg-half;
    }
}


.item-grid--single {
    .item-grid__item {
        width: 100%;
    }
}

.item-grid--double {
    .item-grid__item {
        width: 50%;

        &:nth-child(odd) {
            clear: left;
        }
    }
}

.item-grid--triple {
    .item-grid__item {
        width: 33.3333%;

        &:nth-child(3n + 1) {
            clear: left;
        }
    }
}

.item-grid--quadruple {
    .item-grid__item {
        width: 25%;

        &:nth-child(4n + 1) {
            clear: left;
        }
    }
}

.item-grid--quintuple {
    .item-grid__item {
        width: 20%;

        &:nth-child(5n + 1) {
            clear: left;
        }
    }
}

.item-grid--sextuple {
    .item-grid__item {
        width: 16.6666%;

        &:nth-child(6n + 1) {
            clear: left;
        }
    }
}

.item-grid--mb {
    .item-grid__item {
        margin-bottom: 1.5rem;
    }
}


@media @q-xxs {
    .item-grid[data-columns-xxs='1'] {
        .item-grid--single;
    }

    .item-grid[data-columns-xxs='2'] {
        .item-grid--double;
    }

    .item-grid[data-columns-xxs='3'] {
        .item-grid--triple;
    }

    .item-grid[data-columns-xxs='4'] {
        .item-grid--quadruple;
    }

    .item-grid[data-columns-xxs='5'] {
        .item-grid--quintuple;
    }

    .item-grid[data-columns-xxs='6'] {
        .item-grid--sextuple;
    }
}


@media @q-xs {
    .item-grid[data-columns-xs='1'] {
        .item-grid--single;
    }

    .item-grid[data-columns-xs='2'] {
        .item-grid--double;
    }

    .item-grid[data-columns-xs='3'] {
        .item-grid--triple;
    }

    .item-grid[data-columns-xs='4'] {
        .item-grid--quadruple;
    }

    .item-grid[data-columns-xs='5'] {
        .item-grid--quintuple;
    }

    .item-grid[data-columns-xs='6'] {
        .item-grid--sextuple;
    }
}

@media @q-sm {
    .item-grid[data-columns-sm='1'] {
        .item-grid--single;
    }

    .item-grid[data-columns-sm='2'] {
        .item-grid--double;
    }

    .item-grid[data-columns-sm='3'] {
        .item-grid--triple;
    }

    .item-grid[data-columns-sm='4'] {
        .item-grid--quadruple;
    }

    .item-grid[data-columns-sm='5'] {
        .item-grid--quintuple;
    }

    .item-grid[data-columns-sm='6'] {
        .item-grid--sextuple;
    }
}

@media @q-md {
    .item-grid[data-columns-md='1'] {
        .item-grid--single;
    }

    .item-grid[data-columns-md='2'] {
        .item-grid--double;
    }

    .item-grid[data-columns-md='3'] {
        .item-grid--triple;
    }

    .item-grid[data-columns-md='4'] {
        .item-grid--quadruple;
    }

    .item-grid[data-columns-md='5'] {
        .item-grid--quintuple;
    }

    .item-grid[data-columns-md='6'] {
        .item-grid--sextuple;
    }
}

@media @q-lg {
    .item-grid[data-columns-lg='1'] {
        .item-grid--single;
    }

    .item-grid[data-columns-lg='2'] {
        .item-grid--double;
    }

    .item-grid[data-columns-lg='3'] {
        .item-grid--triple;
    }

    .item-grid[data-columns-lg='4'] {
        .item-grid--quadruple;
    }

    .item-grid[data-columns-lg='5'] {
        .item-grid--quintuple;
    }

    .item-grid[data-columns-lg='6'] {
        .item-grid--sextuple;
    }
}

@media @q-xl {
    .item-grid[data-columns-xl='1'] {
        .item-grid--single;
    }

    .item-grid[data-columns-xl='2'] {
        .item-grid--double;
    }

    .item-grid[data-columns-xl='3'] {
        .item-grid--triple;
    }

    .item-grid[data-columns-xl='4'] {
        .item-grid--quadruple;
    }

    .item-grid[data-columns-xl='5'] {
        .item-grid--quintuple;
    }

    .item-grid[data-columns-xl='6'] {
        .item-grid--sextuple;
    }
}


.item-grid--gutter-vertical {
    .item-grid__item {
        padding-top: @item-gutter--xxs-half;
        padding-bottom: @item-gutter--xxs-half;

        @media @q-xs-min {
            padding-top: @item-gutter--xs-half;
            padding-bottom: @item-gutter--xs-half;
        }

        @media @q-sm-min {
            padding-top: @item-gutter--sm-half;
            padding-bottom: @item-gutter--sm-half;
        }

        @media @q-md-min {
            padding-top: @item-gutter--md-half;
            padding-bottom: @item-gutter--md-half;
        }

        @media @q-lg {
            padding-top: @item-gutter--lg-half;
            padding-bottom: @item-gutter--lg-half;
        }
    }
}

.item-grid--gutter-sm {
    width: ~'calc(100% + @{item-gutter--xxs} / 2)';
    margin-left: -@item-gutter--xxs-half / 2;

    @media @q-xs-min {
        width: ~'calc(100% + @{item-gutter--xs} / 2)';
        margin-left: -@item-gutter--xs-half / 2;
    }

    @media @q-sm-min {
        width: ~'calc(100% + @{item-gutter--sm} / 2)';
        margin-left: -@item-gutter--sm-half / 2;
    }

    @media @q-md-min {
        width: ~'calc(100% + @{item-gutter--md} / 2)';
        margin-left: -@item-gutter--md-half / 2;
    }

    @media @q-lg {
        width: ~'calc(100% + @{item-gutter--lg} / 2)';
        margin-left: -@item-gutter--lg-half / 2;
    }

    .item-grid__item {
        padding: 0 @item-gutter--xxs-half / 2;

        @media @q-xs-min {
            padding: 0 @item-gutter--xs-half / 2;
        }

        @media @q-sm-min {
            padding: 0 @item-gutter--sm-half / 2;
        }

        @media @q-md-min {
            padding: 0 @item-gutter--md-half / 2;
        }

        @media @q-lg {
            padding: 0 @item-gutter--lg-half / 2;
        }
    }
}