/*
|--------------------------------------------------------------------------
| Helpers: Utilities
|--------------------------------------------------------------------------
|
| Globally useful helper classes are declared here.
|
*/

@import 'utilities/**/*.less';

.cover {
    .cover-block();
}