/* ==========================================================================
    Legend
   ========================================================================== */
legend {
    margin-bottom: 1rem;
    .font-size(1rem, 1.25rem, 1.5rem);
}


/* ==========================================================================
    Labels
   ========================================================================== */
label {
    display: inline-block;
    margin-bottom: .25em;

    .antialiased();
    .font-size(1rem, 1.125rem, 1.25rem);

    &.required:after {
        content: '*';
    }
}

.form__label--large {
    .font-size(1rem, 1.25rem, 1.5rem);
}


/* ==========================================================================
    Input & textarea
   ========================================================================== */
.form {

    /* All inputs + textarea, base styling */
    input[type='text'],
    input[type='password'],
    input[type='date'],
    input[type='datetime'],
    input[type='datetime-local'],
    input[type='number'],
    input[type='email'],
    input[type='month'],
    input[type='search'],
    input[type='tel'],
    input[type='url'],
    input[type='week'],
    input[type='time'],
    textarea {
        width: 100%;

        .antialiased();
        .font-primary();
        .font-size(1rem, 1.125rem, 1.25rem);
        .placeholder(fade(@color-darkest, 18%));
        color: @color-primary;

        border: 2px solid rgba(0, 58, 68, .18);
        box-shadow: inset 1px 1px 6px 0 rgba(0, 58, 68, .1);
        background-color: @color-lightest;

        transition: @transition-input;
        outline: none;

        &.error,
        &.parsley-error {
            background-color: fade(@color-highlight-error, 16%);
        }

        &.success,
        &.parsley-success {
            background-color: fade(@color-highlight-success, 16%);
        }
    }


    /* Text input specific styles */
    input[type='text'],
    input[type='password'],
    input[type='date'],
    input[type='datetime'],
    input[type='datetime-local'],
    input[type='number'],
    input[type='email'],
    input[type='month'],
    input[type='search'],
    input[type='tel'],
    input[type='url'],
    input[type='week'],
    input[type='time'] {
        height: @input-height-default-mobile;
        padding: 0 1rem;
        line-height: @input-height-default-mobile;

        &.form__field--large {
            height: @input-height-large-mobile;
            line-height: @input-height-large-mobile;
            .font-size(1rem, 1.25rem, 1.5rem);
        }

        @media @q-sm-min {
            height: @input-height-default-desktop;
            line-height: @input-height-default-desktop;

            &.form__field--large {
                height: @input-height-large-desktop;
                line-height: @input-height-large-desktop;
            }
        }

    }

    input[type='file'] {
        line-height: 1;
        font-size: 1rem;
    }

    textarea {
        padding: 1rem;
    }
}
