.antialiased() {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.font-size(@xs, @sm: @xs, @md: @sm, @lg: @md) {
    font-size: @xs;

    @media @q-sm-min {
        font-size: @sm;
    }

    @media @q-md-min {
        font-size: @md;
    }

    @media @q-lg-min {
        font-size: @lg;
    }
}