/*
|--------------------------------------------------------------------------
| Lists
|--------------------------------------------------------------------------
|
| Default way lists are styled.
|
| Important: don't get too specific on the base ul & ol styles as it
| will bite you later on.
|
*/

ul {

}

ol {

}

.list-clean,
.list--clean {
    padding: 0;
    margin: 0;

    list-style-type: none;

    li {
        margin-left: 0;
        padding-left: 0;

        &:before {
            display: none;
            visibility: hidden; //for screenreader
        }
    }
}

.list-inline,
.list--inline {
    .clearfix;
    padding: 0;
    margin: 0;

    list-style-type: none;

    li {
        display: block;
        margin: 0 .25rem 0 0;
        padding: 0;
        float: left;
    }
}

.list--buttons {
    .list--clean();
    .list--inline();
    display: inline-block;
    vertical-align: middle;

    li {
        margin: 0 .5rem 0 0;

        &:last-child {
            margin-right: 0;
        }
    }

    &[data-align='right'] {
        text-align: right;
    }
}

.list--partners {
    .list--clean();
    .list--inline();
    text-align: center;

    li {
        float: none;
        display: inline-block;
        margin: .5rem .375rem;
        vertical-align: middle;
    }

    li.dimdocs {
        img {
            width: 4rem;
        }
    }

    li.npo2 {
        img {
            width: 2.375rem;
        }
    }

    li.bnnvara {
        img {
            width: 4.5rem;
        }
    }

    li.cobo {
        img {
            width: 2.875rem;
        }
    }

    li.npo-fonds {
        img {
            width: 3.75rem;
        }
    }

    @media @q-xs-min {
        li {
            margin: .75rem 1rem;
        }
    }

    @media @q-sm-min {
        li {
            margin: 1rem 2rem;

            &.dimdocs,
            &.npo2,
            &.bnnvara,
            &.cobo,
            &.npo-fonds {
                img {
                    width: auto;
                }
            }
        }
    }
}