.mosul-video {

}

.mosul-video__body {
    .page-container();
}

.mosul-video__compare {
    @media @q-xs-max {
        display: none;
    }
}

.mosul-video__fallback {
    display: none;

    @media @q-xs-max {
        display: block;
    }
}

.mosul-video__header {
    .clearfix();

    @media @q-xs-max {
        display: none;
    }
}

.mosul-video__label-left,
.mosul-video__label-right {
    float: left;
    width: 50%;
    margin-bottom: 1rem;
    .font-size(.875rem, 1.25rem, 1.5rem);
}

.mosul-video__label-right {
    float: right;
    text-align: right;
}

.mosul-video--embeddable {
    padding-top: 1rem;
    padding-bottom: 1rem;
}